import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './CustomerLandingStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import { routes } from '../../Routes';
import { BUNDLES_PRIORITY, LABELS, STEPS, WHY_CHOOSE } from './CustomerLandingConstants';
import { BookingActions } from '../../store/actions/BookingActions';
import MiddysFooter from '../../components/middysFooter/MiddysFooter';
import { mockDataServices } from '../../mock/BundleMock';
import { BundlePackage, OfferCategory } from '../../models/bundles/Bundles';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import BundleCardLoading from '../../components/bundleCard/BundleCardLoading';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import homeBanner from '../../assets/home_banner.png';
import metroMap from '../../assets/Map.jpg';
import FAQQuestion from './faqQuestion/FAQQuestion';
import { getCategoryDescription } from './CustomerLandingUtils';
import HighlightedPackage from './highlightedPackage/HighlightedPackage';
import { BundleCardType } from '../../components/bundleCard/BundleCardConstants';
import { Box, Typography } from '@material-ui/core';
import SearchBar from '../landingScreen/components/searchBar/SearchBar';
import ActionButton from '../../components/actionButton/ActionButton';
import { ListBox } from '../../components/listBox/ListBox';

interface CustomerLandingProps extends RouteComponentProps {
  bundles: BundlePackage[] | undefined;
  loadingBundles: boolean;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updatePostcode: (data: string) => void;
}

const CustomerLanding: FC<CustomerLandingProps> = ({
  history,
  bundles,
  loadingBundles,
  accessToken,
  getBundlesRequest,
  updatePostcode,
}) => {
  const styles = useStyles();

  useEffect(() => {
    getBundlesRequest();
    updatePostcode('');
  }, [accessToken]);

  const packages = useMemo<OfferCategory[]>(() => {
    const bundlePackages = bundles && bundles.length > 0 ? bundles : [];
    let result: OfferCategory[] = [];
    bundlePackages
      .filter((p) => !!p.category)
      .map((p) => {
        if (!result.some((obj) => obj.title === p.category!.title)) {
          result.push(p.category!);
        }
      });

    return result;
  }, [bundles]);

  return (
    <div>
      <Header />
      <BackgroundCover
        text={LABELS.PAGE_SUBTITLE}
        landing
        loading={loadingBundles}
        backgroundImage={homeBanner}
        imgPosition="right bottom"
      >
        <div className={styles.container}>
          <div className={styles.actionButton} onClick={() => history.push(routes.packages)}>
            {LABELS.SEE_ALL}
          </div>
        </div>
        <div className={styles.container}>
          <Box mt={2}>
            <SearchBar />
          </Box>
        </div>
      </BackgroundCover>
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <div className={styles.section}>
              <ListBox spacing={1}>
                <div className={styles.title}>{LABELS.START_HERE}</div>
                <div className={styles.description}>{LABELS.ABOUT_DESCRIPTION}</div>
              </ListBox>
              <div className={styles.highlightedPackagesContainer}>
                {mockDataServices!.map((ms) => (
                  <HighlightedPackage
                    servicePackage={ms as BundleCardType}
                    actionLabel={ms.actionLabel!}
                  />
                ))}
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.servicingContent}>
                <img className={styles.mapImage} src={metroMap} />

                <div className={styles.title}>{LABELS.SERVICING_METRO_ONLY}</div>
                <div className={styles.description}>{LABELS.METRO_DESCRIPTION}</div>
              </div>

              <ActionButton
                onClick={() => {
                  history.push(routes.registerInterest);
                }}
                variant="outlined"
              >
                {LABELS.GET_NOTIFIED}
              </ActionButton>
            </div>
          </div>

          <div className={styles.section}>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gridRowGap="1em"
            >
              <Typography variant="h2" className="title" color="secondary">
                {LABELS.ABOUT_TITLE}
              </Typography>
              <ActionButton
                variant="outlined"
                onClick={() => {
                  history.push(routes.packages);
                }}
              >
                {LABELS.VIEW_ALL}
              </ActionButton>
            </Box>
            <div className={styles.description}>{LABELS.ABOUT_DESCRIPTION}</div>
            <div className={styles.offersGrid}>
              {!bundles || loadingBundles ? (
                <>
                  {[...Array(6).fill(null)].map((_, idx) => (
                    <BundleCardLoading key={idx} />
                  ))}
                </>
              ) : (
                <>
                  {packages!
                    .sort((p1, p2) => {
                      if (BUNDLES_PRIORITY.indexOf(p1.title) < BUNDLES_PRIORITY.indexOf(p2.title)) {
                        return -1;
                      } else {
                        return 1;
                      }
                    })
                    .map((p, idx) => (
                      <div
                        className={styles.categoryContainer}
                        key={idx}
                        onClick={() =>
                          history.push({ pathname: routes.packages, search: `?filter=${p.title}` })
                        }
                      >
                        <div className={styles.imageContainer}>
                          <img src={p.image} className={styles.image} alt="bundle" />
                        </div>
                        <div className={styles.categoryContentContainer}>
                          <div className={styles.categoryTitle}>{p.title}</div>
                          <div className={styles.categoryDescription}>
                            {getCategoryDescription(p.title)}
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>

          <HowItWorksSection steps={STEPS} title={LABELS.HOW_IT_WORKS} />
          <div className={styles.sectionContainer}>
            <div className={`${styles.sectionTitle} title`}>{LABELS.FAQS}</div>
            {WHY_CHOOSE.map((why, idx) => (
              <FAQQuestion key={idx} question={why.title} description={why.text} />
            ))}
          </div>
        </div>
      </div>
      <MiddysFooter showRegisterInterestBanner />
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingBundles: loading(state),
  bundles: state.bundlesState.bundles,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerLanding));
