import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  bundleContainer: {
    position: 'relative',
    width: '100%',
    border: `${theme.colors.borderColor} 2px solid`,
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    height: 180,
    width: '100%',
    ...loadingAnimation,
  },
  image: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'cover',
    '&.fan': {
      objectPosition: 'top',
    },
  },
  contentContainer: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.black87,
    marginBottom: theme.units.margin * 2,
  },
  description: {
    fontFamily: theme.font.family,
    fontSize: theme.font.s,
    color: theme.colors.black60,
    marginBottom: theme.units.margin * 4,
  },
  priceRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 'auto',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  price: {
    marginTop: 'auto',
    fontWeight: 500,
    fontFamily: theme.font.family,
    fontSize: theme.font.xl,
    color: theme.colors.secondary,
  },
  from: {
    fontFamily: theme.font.family,
    fontSize: theme.font.s,
    color: theme.colors.black38,
    position: 'relative',
  },
  actionButton: {
    marginTop: 'auto',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 100,
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  // LOADING
  loadingBackground: {
    background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
    backgroundSize: '200% 100%',
    animation: '1.5s shine linear infinite',
    position: 'relative',
    height: 370,
    width: '100%',
    overflow: 'hidden',
    borderRadius: theme.units.borderRadius * 3,
    backgroundColor: theme.colors.offerOverlay,
    display: 'flex',
  },
  offerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.units.padding * 5,
    flex: 1,
  },
  loadingTagTitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.xxl,
    fontWeight: 600,
    color: theme.colors.light,
    marginBottom: theme.units.margin,
    width: 120,
    height: theme.units.iconHeight,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
  },
  loadingTagSubtitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.s,
    color: theme.colors.light,
    width: 160,
    height: theme.units.iconHeight / 1.5,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
  },
  topLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
  },
  bottomRow: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    zIndex: 2,
  },
  tagContainer: {},
  loadingIcon: {
    width: theme.units.iconHeight,
    height: theme.units.iconHeight,
    marginRight: theme.units.padding * 2,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
  },
  loadingIconText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.light,
    backgroundColor: theme.colors.light,
    width: 100,
    height: theme.units.iconHeight,
    borderRadius: theme.units.borderRadius,
  },
  loadingActionButton: {
    height: 28,
    minWidth: 70,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
  },
});
