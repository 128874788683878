import { theme } from '../../theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer, loadingAnimation } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';
import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
  },
  container: {
    ...pageContainer,
    marginTop: 0,
    // width: '100%',
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.units.padding * 6,
    paddingBottom: theme.units.padding * 6,
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingTop: theme.units.padding * 4,
      paddingBottom: 0,
    },
  },
  homeIcon: {
    height: 18,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  navArrow: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.black60,
    marginLeft: theme.units.margin * 4,
    marginRight: theme.units.margin * 3,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  navTitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.black60,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  heroImageContainer: {
    width: '100%',
    position: 'relative',
    height: 400,
    display: 'flex',
    alignItems: 'center',
  },
  heroImageWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  heroImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    '&.fan': {
      objectPosition: 'top',
    },
  },
  arrowsContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: theme.units.margin * 6,
    marginRight: theme.units.margin * 6,
    justifyContent: 'space-between',
    zIndex: 1,
  },
  arrow: {
    height: 35,
    cursor: 'pointer',
  },
  bundleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.units.margin * 16,
    paddingBottom: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  bundleContent: {
    width: '100%',
    maxWidth: 600,
    marginRight: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  subheading: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
  },
  iframe: {
    width: '100%',
    height: 400,
    marginTop: theme.units.margin * 6,
    marginBottom: theme.units.margin * 6,
  },
  title: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginTop: theme.units.margin * 4,
    marginBottom: theme.units.margin * 4,
  },
  description: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    whiteSpace: 'pre-wrap',
  },
  bundleActionsContainer: {
    marginTop: theme.units.margin * 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bundleAction: {
    display: 'flex',
    alignItems: 'center',
  },
  actionIcon: {
    height: 35,
    marginRight: theme.units.margin * 3,
  },
  actionContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionSubtitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.xs,
    color: theme.colors.secondary,
  },
  actionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionTitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.l,
    color: theme.colors.secondary,
    marginRight: theme.units.margin * 3,
  },
  actionTitleIcon: {
    width: 16,
  },
  priceContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: 400,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      maxWidth: '100%',
    },
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: 250,
    backgroundColor: theme.colors.disabled,
    marginTop: theme.units.margin * 4,
  },
  from: {
    fontFamily: theme.font.family,
    fontSize: theme.font.s,
    color: theme.colors.black60,
    fontWeight: 500,
  },
  price: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxxl,
    color: theme.colors.buttonPrimary,
    marginTop: theme.units.margin * 2,
  },
  priceGST: {
    fontSize: theme.font.s,
  },
  howDoEstimates: {
    marginTop: theme.units.margin * 4,
    fontFamily: theme.font.family,
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: theme.font.m,
    color: theme.colors.black60,
    alignSelf: 'center',
  },
  contentImageContainer: {
    width: '100%',
    maxWidth: 600,
    marginLeft: theme.units.margin * 12,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      marginLeft: 0,
    },
  },
  contentImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  actionButton: {
    marginTop: theme.units.margin * 6,
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 100,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addressFooter: {
    width: '100%',
    backgroundColor: theme.colors.secondary,
    paddingTop: theme.units.padding * 4,
    paddingBottom: theme.units.padding * 4,
    marginTop: theme.units.margin * 8,
  },
  addressFooterContent: {
    ...pageContainer,
    width: '100%',
  },
  addressRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addressRowIcon: {
    height: 30,
    marginRight: theme.units.margin * 4,
  },
  addressRowTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginRight: 'auto',
  },
  addressButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  footerContainer: {
    backgroundColor: theme.colors.light,
    paddingTop: theme.units.padding * 8,
    paddingBottom: theme.units.padding * 8,
  },
  footerContentWrapper: {
    ...pageContainer,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  modal: {},
  modalImageContainer: {
    width: '100%',
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
  modalImageContain: {
    objectFit: 'contain',
    objectPosition: 'center',
    width: '80%',
    height: '80%',
  },
  modalContent: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginBottom: theme.units.margin * 4,
    textAlign: 'center',
  },
  modalText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    marginBottom: 40,
    textAlign: 'center',
    maxWidth: 450,
    marginLeft: 20,
    marginRight: 20,
  },
  inclusionsContainer: {
    marginTop: theme.units.margin * 16,
    paddingBottom: theme.units.margin * 8,
  },
  inclusionsRow: {
    marginTop: theme.units.margin * 6,
  },
  inclusionContainer: {
    position: 'relative',
    width: '100%',
    minHeight: 370,
    border: `${theme.colors.borderColor} 2px solid`,
    display: 'flex',
    flexDirection: 'column',
  },
  inclusionImageContainer: {
    height: 240,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inclusionImage: {
    width: '80%',
    height: '80%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  inclusionContentContainer: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  inclusionTitle: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.black87,
    marginBottom: theme.units.margin * 2,
  },
  inclusionButton: {
    position: 'relative',
    left: -2,
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    padding: theme.units.padding * 2,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 140,
    width: '100%',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  stepTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    fontWeight: 500,
    color: theme.colors.darkGrey,
    marginBottom: theme.units.margin * 2,
    marginTop: theme.units.margin * 6,
  },
  stepText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
  },
  // Loading
  loadingHeroContainer: {
    ...loadingAnimation,
    width: '100%',
    position: 'relative',
    height: 400,
    display: 'flex',
    alignItems: 'center',
  },
  loadingArrow: {
    height: 45,
    width: 45,
    backgroundColor: theme.colors.light,
    borderRadius: 5000,
  },
  loadingSubheading: {
    width: 100,
    height: theme.units.iconHeight,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
  },
  loadingTitle: {
    width: 320,
    height: theme.units.iconHeight * 1.5,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
    marginTop: theme.units.margin * 4,
    marginBottom: theme.units.margin * 4,
  },
  loadingDescription: {
    width: '100%',
    height: 180,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
  },
  loadingButton: {
    width: 160,
    height: theme.units.iconHeight * 2,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
    marginTop: theme.units.margin * 4,
  },
  loadingImage: {
    width: '100%',
    maxWidth: 600,
    marginLeft: theme.units.margin * 12,
    ...loadingAnimation,
    height: 400,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      marginLeft: 0,
    },
  },
  loadingToggleContainer: {
    display: 'flex',
    marginBottom: theme.units.margin * 6,
  },
  loadingToggle: {
    width: 120,
    height: theme.units.iconHeight * 2,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
    marginLeft: theme.units.margin * 4,
  },
  loadingPriceContainer: {
    width: '100%',
    height: 200,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
  },
  loadingInclusionContainer: {
    position: 'relative',
    width: '100%',
    ...loadingAnimation,
    display: 'flex',
    flexDirection: 'column',
  },
  loadingInclusionText: {
    width: 100,
    marginLeft: theme.units.margin * 4,
    height: 20,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
    marginTop: 180,
    marginBottom: theme.units.margin * 2,
  },
  loadingInclusionText2: {
    width: 140,
    height: 20,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
    marginBottom: theme.units.margin * 4,
    marginLeft: theme.units.margin * 4,
  },
  loadingInclusionButton: {
    margin: theme.units.margin * 4,
    height: 40,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
  },
  iconStyle: {
    width: theme.units.iconWidth * 1.5,
    height: theme.units.iconHeight * 1.5,
    objectFit: 'contain',
    cursor: 'pointer',
  },
  headerButton: {
    position: 'relative',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    padding: theme.units.padding * 2,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 180,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  specRow: {
    display: 'flex',
    // justifyContent: 'space-between',
  },
  specKey: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.black87,
    lineHeight: 2,
  },
  specValue: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 2,
    marginLeft: theme.units.margin * 4,
    textAlign: 'left',
  },
  specsGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
});

export const StyledToggleButtonGroup = withStyles(() => ({
  'root': { marginRight: -theme.units.margin * 2 },
  grouped: {
    margin: theme.units.margin * 2,
    border: 'none',
    paddingLeft: theme.units.padding * 4,
    paddingRight: theme.units.padding * 4,
    backgroundColor: theme.colors.disabled,
    '&:not(:first-child)': {
      borderRadius: 0,
    },
    '&:first-child': {
      borderRadius: 0,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: `${theme.colors.light} !important`,
      backgroundColor: `${theme.colors.buttonPrimary} !important`,
    },
  },
}))(ToggleButtonGroup);
