import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './LandingScreenStyles';
import { withRouter, RouteComponentProps, useLocation, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import { routes } from '../../Routes';
import SearchBar from './components/searchBar/SearchBar';
import { LABELS } from './LandingScreenConstants';
import { BookingActions } from '../../store/actions/BookingActions';
import MiddysFooter from '../../components/middysFooter/MiddysFooter';
import BundleCard from '../../components/bundleCard/BundleCard';
import Offer from '../../components/offer/Offer';
import { mockDataServices, mockOffer } from '../../mock/BundleMock';
import { BundlePackage, CategoryPackage, OfferCategory } from '../../models/bundles/Bundles';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import BundleCardLoading from '../../components/bundleCard/BundleCardLoading';
import { AppSettings } from '../../AppSettings';
import { Box, Typography } from '@material-ui/core';
import { ListBox } from '../../components/listBox/ListBox';
import { getIcon } from '../../components/header/packagesMenu/PackagesMenuConstants';
import ActionButton from '../../components/actionButton/ActionButton';

interface LandingScreenProps extends RouteComponentProps {
  bundles: BundlePackage[] | undefined;
  loadingBundles: boolean;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updateSelectedBundle: (data: BundlePackage | undefined) => void;
  updatePostcode: (data: string) => void;
}

const LandingScreen: FC<LandingScreenProps> = ({
  history,
  bundles,
  loadingBundles,
  accessToken,
  getBundlesRequest,
  updateSelectedBundle,
  updatePostcode,
}) => {
  const styles = useStyles();
  const selectedCategories =
    new URLSearchParams(useLocation().search).get('filter')?.split(',') || [];

  useEffect(() => {
    getBundlesRequest();
    updatePostcode('');
  }, [accessToken]);

  const sections = useMemo<CategoryPackage[] | undefined>(() => {
    if (!bundles) return undefined;
    let result: CategoryPackage[] = [];

    bundles
      .filter((b) => !!b.category)
      .forEach((p) => {
        const existingCategory = result.find((r) => r.category.title === p.category!.title);
        if (existingCategory) {
          existingCategory.packages.push(p);
        } else {
          result.push({
            category: p.category!,
            packages: [p],
          });
        }
      });

    return result;
  }, [bundles]);

  return (
    <div>
      <Header />
      <BackgroundCover title="Offering you more..." landing loading={loadingBundles}>
        <div className={styles.container}>
          <SearchBar loading={loadingBundles} />
        </div>
      </BackgroundCover>
      <div className={styles.content}>
        <div className={styles.container}>
          <ListBox mt={6} mb={3} spacing={2}>
            <Typography variant="h2" color="secondary" className="title">
              {LABELS.PACKAGES}
            </Typography>
            <Typography>{LABELS.PACKAGES_DESCRIPTION}</Typography>
          </ListBox>

          {sections && (
            <ListBox mt={3} mb={!bundles || loadingBundles ? 2 : 0} spacing={2}>
              <Typography variant="h6" color="textSecondary">
                {LABELS.FILTER_PACKAGES}
              </Typography>
              <ListBox flexWrap="wrap" direction="row" spacing={8} gridRowGap="1em">
                {[...sections, { category: { title: 'Services' } }].map((s) => {
                  const category = s.category.title;
                  return (
                    <ListBox
                      className={`${styles.categoryFilterButton} ${
                        selectedCategories.includes(category) ? styles.selectedCategory : ''
                      }`}
                      alignItems="center"
                      flexDirection="row"
                      spacing={1}
                      p={2}
                      onClick={() => {
                        let searchString = selectedCategories.includes(category)
                          ? selectedCategories.filter((sc) => sc !== category).join(',')
                          : [...selectedCategories, category].join(',');

                        history.push({
                          pathname: location.pathname,
                          search: searchString.length > 0 ? `?filter=${searchString}` : '',
                        });
                      }}
                    >
                      <img src={getIcon(category)} />
                      <Typography>{category}</Typography>
                    </ListBox>
                  );
                })}
                {selectedCategories.length > 0 && (
                  <Box display="flex" p={2} alignItems="center" justifyContent="center">
                    <ActionButton
                      onClick={() => {
                        history.push({
                          pathname: location.pathname,
                          search: '',
                        });
                      }}
                      variant="outlined"
                    >
                      {LABELS.CLEAR_SELECTION}
                    </ActionButton>
                  </Box>
                )}
              </ListBox>
            </ListBox>
          )}

          <div className={styles.offersGrid}>
            {(!bundles || loadingBundles) && (
              <>
                {[...Array(4).fill(null)].map((_, idx) => (
                  <BundleCardLoading key={idx} />
                ))}
              </>
            )}
          </div>
          {sections && !!sections.length && (
            <>
              {sections
                .filter(
                  (s) =>
                    selectedCategories.length === 0 ||
                    selectedCategories.includes(s.category.title),
                )
                .map((s, i) => (
                  <div key={i}>
                    <Box my={3}>
                      <Typography variant="h3" className="title" color="secondary">
                        {s.category.title}
                      </Typography>
                    </Box>
                    <div className={styles.offersGrid}>
                      {s.packages
                        .sort((a, b) => a.standard.price - b.standard.price)
                        .map((p, idx) => (
                          <BundleCard
                            key={idx}
                            bundleCard={{
                              title: p.packageName ? p.packageName : p.standard.title,
                              image: p.thumbnail || p.standard.heroImage,
                              description: p.standard.description,
                              price: p.standard.price,
                              skills: p.skills,
                              id: p.productId,
                              postcodes: p.postcodes,
                              category: p.category,
                            }}
                            onClick={() => {
                              updateSelectedBundle(p);
                              history.push(`${routes.bundleDetails}?bundleId=${p.productId}`);
                            }}
                          />
                        ))}
                    </div>
                  </div>
                ))}
            </>
          )}

          {(selectedCategories.length === 0 || selectedCategories.includes('Services')) && (
            <>
              <div>
                <div className={styles.allOffersText}>{LABELS.SERVICES}</div>

                <div className={styles.servicesGrid}>
                  {loadingBundles ? (
                    <>
                      {[...Array(3).fill(null)].map((_, idx) => (
                        <BundleCardLoading key={idx} />
                      ))}
                    </>
                  ) : (
                    <>
                      <BundleCard
                        bundleCard={mockDataServices[0]}
                        onClick={() => {
                          updateSelectedBundle(undefined);
                          history.push(mockDataServices[0].location!);
                        }}
                      />

                      <Offer
                        image={mockOffer.image}
                        title={mockOffer.title}
                        description={mockOffer.description}
                        button={mockOffer.button}
                        onClick={() => window.open(AppSettings.server.tradesUIURL, '_blank')}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Box mt={8}>
        <MiddysFooter showRegisterInterestBanner />
      </Box>
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingBundles: loading(state),
  bundles: state.bundlesState.bundles,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updateSelectedBundle: (data: BundlePackage | undefined) =>
    dispatch(BundlesActions.updateSelectedBundle(data)),
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingScreen));
