export const LABELS = {
  GET_FREE_QUOTES: 'Get a Free Quote',
  DETAILS: 'Details',
  STANDARD: 'Standard',
  UPGRADED: 'Upgraded',
  CHECK: 'Check availability in your area',
  ADD_ADDRESS: 'Add your address here',
  ADD_ADDRESS2: 'Add address',
  FULL_PACKAGE: 'Full package specifications',
  DOWNLOAD_PDF: 'Download PDF',
  STANDARD_PACKAGE: (label?: string) => `${label || 'Standard'} Package Includes`,
  UPGRADED_PACKAGE: (label?: string) => `${label || 'Upgraded'} Package Includes`,
  HOW_DO_EST: 'How do estimate prices work?',
  PRICE_FROM: 'Fully installed from',
  CHECK_PRODUCT: 'Check that this product is available in your area',
  MODAL_TITLE: 'Price estimate',
  MODAL_TEXT:
    "This 'Starting from' price excludes GST and is based on a standard installation, and includes the cost of the listed hardware inclusions and labour. Submit your request and our accredited trades will contact you for additional information and provide the best solution to meet your individual needs. Not included: Travel beyond 30km from the electrical contractor’s store of purchase, electrical conduit, power board upgrades, solid wall core hole penetration, double brick penetration, removal & replacement of tin roofing, cost of access machinery if required",
  MODAL_BUTTON: 'Got it',
  VIEW: 'View',
  HOW_IT_WORKS: 'Book a package in 4 easy steps',
  WHY_CHOOSE: 'Why choose a bundled package',
  LINK_PREFIX: 'https://www.youtube.com/embed/',
  LINK_TO_REPLACE: 'https://youtu.be/',
  GET_INSPIRED: 'Get Inspired',
  BOOK_CONSULT: ' and book a consult with one of our helpful team.',
};

export const STEPS = [
  {
    title: 'Check package availability ',
    text: 'Simply enter your postcode to check if the package is available in your area. If not, make sure to sign up to our email updates!',
  },
  {
    title: 'Book the package, pay nothing',
    text: 'Select your preferred installation timeframe and upload a couple of artefacts. You don’t have to make a payment when submitting your booking request.',
  },
  {
    title: 'Confirm the job',
    text: 'Post an initial consultation with the trade, you will receive a notification that includes the final installation cost and time. Confirm the job, via your email or the Sorted App. ',
  },
  {
    title: 'Get the job done',
    text: "Your selected trade will arrive to do the job at the agreed time. Once the job is completed, your trade will confirm you're satisfied with the job and payment can then be finalised between you and the trade.",
  },
];

export const WHY_CHOOSE = [
  {
    title: 'Quality Products from Recognised Brands',
    text: 'You can trust that Middy’s electricians use top-quality products from reputable brands. Our electrical customers are fully qualified, registered electricians committed to high standards.',
  },
  {
    title: 'Fast, Reliable and Transparent Service',
    text: 'Electricians registered through Middy’s partnered platform, Sorted, respond quickly to your requests. Our package price proposal includes both material and installation costs, with payment required only after the job is completed.',
  },
  {
    title: 'Manage Everything in the App',
    text: 'The Sorted App makes the entire process quick, easy, and transparent. Track and confirm your request, view completed job details, and receive your invoice—all in one app! Middy’s network of trusted electrical customers are monitored through homeowner reviews and star ratings, ensuring peace of mind and high-quality outcomes for your project.',
  },
];
